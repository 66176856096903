import React, { Fragment } from 'react'

import Layout from 'layouts/Page'
import SEO from 'components/shared/SEO'
import PasswordForgotForm from 'components/Auth/PasswordForgot'
import { AuthService } from 'services'

class PasswordForgotPage extends React.Component {
  onSubmit = async email => {
    try {
      await AuthService.doPasswordReset(email)
    } catch (error) {
      return error
    }
  }

  render() {
    return <PasswordForgotForm onSubmit={this.onSubmit} />
  }
}

export default ({ location }) => (
  <Layout navbar="auth" location={location} displayFooter={false}>
    <SEO title="Password Recover" />
    <PasswordForgotPage />
  </Layout>
)
