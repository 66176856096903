import React, { Component } from 'react'

import Button from 'components/shared/Button'
import Card from 'components/shared/Card'
import {
  Alert,
  FormInput,
  FormContainer,
  FormRow,
  FormCol,
} from 'components/shared/Forms'

const INITIAL_STATE = {
  email: '',
  error: null,
  success: false,
}

export default class PasswordForgotForm extends Component {
  state = {
    ...INITIAL_STATE,
  }

  onSubmit = event => {
    event.preventDefault()

    const { email } = this.state
    ;(async () => {
      const error = await this.props.onSubmit(email)
      if (error) {
        this.setState({ error: true, success: false })
      } else {
        this.setState({ success: true, error: null })
      }
    })()
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { email, error, success } = this.state

    const isInvalid = email === ''

    return (
      <FormContainer>
        <Card title="Password Recovery">
          <form onSubmit={this.onSubmit}>
            <FormRow>
              <FormCol>
                <FormInput label="Email signed up with">
                  <input
                    type="email"
                    name="email"
                    autoFocus
                    required
                    maxLength="250"
                    value={email}
                    onChange={this.onChange}
                  />
                </FormInput>
              </FormCol>
            </FormRow>
            <Button big full disabled={isInvalid}>
              Recover
            </Button>
            <Alert
              type="success"
              visible={success}
              message="A recovery link has successfully been sent to your email"
            />
            <Alert
              type="error"
              visible={error}
              message="There was an error sending the recovery link"
            />
          </form>
        </Card>
      </FormContainer>
    )
  }
}
